(function ($, site, generic) {
  site.template = site.template || {};

  /**
   * Add to Cart event
   */
  $(document).bind('addToCart.success', function (e, cartResult) {
    generic.overlay.hide();

    if (_.isUndefined(cartResult.trans_data) || _.isUndefined(cartResult.ac_results)) {
      return;
    }
    var args = {
      trans: cartResult.trans_data,
      items: cartResult.trans_data.order.items,
      newItemProdId: cartResult.ac_results[0].result.CARTITEM['prod.PRODUCT_ID'],
      newItemSkuId: cartResult.ac_results[0].result.CARTITEM['sku.SKU_ID'],
      newItemQty: Math.max(
        1,
        cartResult.ac_results[0].result.CARTITEM.ITEM_QUANTITY - cartResult.ac_results[0].result.PREVIOUS_ITEM_QUANTITY
      )
    };

    $(document).trigger('cart_overlay_launch', args);
  });

  Drupal.behaviors.cartConfirmV2 = {
    attached: false,
    attach: function () {
      if (this.attached) {
        return;
      }
      this.attached = true;

      $(document).on('cart_confirm', function () {
        generic.jsonrpc.fetch({
          method: 'trans.get',
          params: [
            {
              trans_fields: ['TRANS_ID', 'totals'],
              payment_fields: [],
              order_fields: ['items', 'samples', 'offerCodes']
            }
          ],
          onSuccess: function (d) {
            var cartResponse = d.getValue();

            if (typeof cartResponse === 'undefined' || !cartResponse) {
              return;
            }

            var args = {
              trans: cartResponse,
              items: cartResponse.order.items
            };

            $(document).trigger('cart_overlay_launch', args);
          }
        });
      });
    }
  };
})(jQuery, window.site || {}, window.generic || {});
